import parse from "html-react-parser";
import Loader from "../../Component/UI/Loader";
import download from "../../assets/img/download-icon.png";
import { useStaticPageData } from "../../Middleware/useStaticPageData";
// import CurrentIssue from "../../Component/Third-PartyAds/CurrentIssue"]
import useFetchMetaTags from "../../Middleware/useFetchMetaTags";
// import { Helmet } from "react-helmet-async";

const MediaPack = () => {
  const { data: mediaPack, isLoading, error } = useStaticPageData("media-pack");
  // const { data: tags } = useFetchMetaTags("media-pack");
  const content =
    typeof mediaPack?.data?.media_pack_content === "string"
      ? mediaPack?.data?.media_pack_content
      : "";
  return (
    <>
      {/* <Helmet>
        <title>{`${tags?.data?.title}`}</title>
        <meta name="description" content={`${tags?.data?.description}`} />
        <link rel="canonical" href={`${tags?.data?.canonical}`} />
        <meta property="og:locale" content={`${tags?.data?.og_locale}`} />
        <meta property="og:type" content={`${tags?.data?.og_type}`} />
        <meta property="og:title" content={`${tags?.data?.og_title}`} />
        <meta
          property="og:description"
          content={`${tags?.data?.og_description}`}
        />
        <meta property="og:url" content={`${tags?.data?.og_url}`} />
        <meta property="og:site_name" content={`${tags?.data?.og_site_name}`} />
        <meta
          property="og:updated_time"
          content={`${tags?.data?.og_updated_time}`}
        />
        <meta
          property="og:image"
          content={`https://admin.asiantrader.biz/wp-content/uploads/sites/1/2024/09/AT-logo-White-3-1.png`}
        />
        <meta
          property="article:published_time"
          content={`${tags?.data?.article_published_time}`}
        />
        <meta
          property="article:modified_time"
          content={`${tags?.data?.article_modified_time}`}
        />
        <meta name="twitter:card" content={`${tags?.data?.twitter_card}`} />
        <meta name="twitter:title" content={`${tags?.data?.twitter_title}`} />
        <meta
          name="twitter:description"
          content={`${tags?.data?.twitter_description}`}
        />
      </Helmet> */}
      <section className="lg:pb-[46px] pb-8 product bg-blur">
        <div className="container mx-auto px-7 relative">
          {isLoading ? (
            <Loader />
          ) : error ? (
            <div></div>
          ) : (
            <>
              <div className="pb-2 text-2xl text-[#231E1E] font-bold border-b border-[#d4d4d4] mb-5 px-3">
                MEDIA PACK
              </div>
              <div className="gap-5 grid grid-cols-12">
                <div className="lg:col-span-9 md:col-span-8 col-span-12">
                  <div className="sm:pl-3 md:pr-12 pl-2 sm:pr-4 pr-2 media-page">
                    {parse(content)}
                  </div>
                  <a
                    target={mediaPack?.data?.media_pack_link?.target}
                    href={mediaPack?.data?.media_pack_link?.url}
                    className="inline-block mt-10"
                  >
                    <div className="border border-[#707070] uppercase  sm:p-5 p-2 md:text-xl text-sm text-[.8rem] flex justify-center items-center sm:gap-10 gap-5 rounded-xl ml-3 sm:mr-12 mr-6 font-bold">
                      {mediaPack?.data?.media_pack_link?.title}

                      <img
                        className="w-auto sm:h-[26px] h-[15px]"
                        src={download}
                        alt="Download Icon"
                      />
                    </div>
                  </a>
                  <div className="mt-[66px] mr-12 text-base">
                    <div className="border-b border-[#d4d4d4] pb-2 mb-8 pl-3 font-bold">
                      For more information contact:
                    </div>
                    {mediaPack?.data?.media_pack_information_contacts?.map(
                      (contact, index) => (
                        <div
                          key={index}
                          className="leading-8 animate__animated animate__fadeInDown pl-3"
                        >
                          {contact.media_pack_information_contacts}
                          <br />
                          {contact.media_pack_information_contact_designation}
                          <br />
                          {contact.media_pack_information_contact_email}
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className="lg:col-span-3 md:col-span-4 col-span-12">
                  <div className="mb-10">
                    <img
                      src={mediaPack?.data?.media_pack_image}
                      alt="Group 215"
                      className="w-full rounded-[10px]"
                    />
                  </div>
                  {/* <div className="bg-[#231E1E] p-5 rounded-[10px]">
                  <CurrentIssue />
                </div> */}
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default MediaPack;
